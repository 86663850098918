// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/landingPage/layouts/header/Header.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/landingPage/layouts/header/Header.tsx");
}
// REMIX HMR END

import React, { useState, useEffect, useRef } from "react";
import "./header.css?__remix_sideEffect__";
import { Link, useLocation, useNavigate } from "@remix-run/react";
import { useOptionalUser } from "~/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
const links = [{
  title: "Facebook",
  url: "https://www.facebook.com",
  icon: faArrowRight
}, {
  title: "Twitter",
  url: "https://www.twitter.com",
  icon: faArrowRight
}, {
  title: "Instagram",
  url: "https://www.instagram.com",
  icon: faArrowRight
}, {
  title: "LinkedIn",
  url: "https://www.linkedin.com",
  icon: faArrowRight
}, {
  title: "YouTube",
  url: "https://www.youtube.com",
  icon: faArrowRight
}];
const menus = [{
  title: "Home",
  url: "/"
}, {
  title: "About",
  url: "/about"
}, {
  title: "Contact",
  url: "/contact"
}, {
  title: "UK Products",
  url: "/products"
}, {
  title: "UK Business",
  url: "/view/business"
}, {
  title: "Career",
  url: "/career"
}];
export default function Header() {
  _s();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const user = useOptionalUser();
  const inputRef = useRef(null);
  const [businesses, setBusinesses] = useState([]);
  const [recentBusinesses, setRecentBusinesses] = useState([]);
  const [products, setProducts] = useState([]);
  const [recentProducts, setRecentProducts] = useState([]);
  const fetchBusinesses = async name => {
    const response = await fetch(`/api/global-search?name=${name}`);
    const data = await response.json();
    setBusinesses(data.businesses);
    setRecentBusinesses(data.recentBusinesses);
    setProducts(data.products);
    setRecentProducts(data.recentProducts);
  };
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      fetchBusinesses(searchTerm);
    }
  }, [activeSearch]);
  const handleSearchChange = e => {
    const term = e.target.value;
    setSearchTerm(term);
    fetchBusinesses(term);
  };
  const handleBusinessClick = id => {
    navigate(`/business-details/${id}`);
  };
  const handleProductClick = id => {
    navigate(`/products/${id}`);
  };
  const highlightMatch = text => {
    const regex = new RegExp(`(${searchTerm})`, "gi");
    return text.replace(regex, match => `<span style="color: white;">${match}</span>`);
  };
  return <header className="lp_header black" onMouseLeave={() => setActiveSearch(false)}>

      <div className="container">
        <div className="content_wrapper">
          <Link to="/" className="left_box">
            <div className="imgbox">
              <img className="logo_light" src="/image/logo.png" alt="" />
              <img className="logo_dark" src="/image/logo-dark.png" alt="" />
            </div>
          </Link>

          <div className="center_box">
            <ul className="menu">
              {menus && menus.map((menu, index) => <li key={index} className={location.pathname === menu.url ? "active" : ""}>

                    <Link to={menu.url}>{menu.title}</Link>
                  </li>)}
              <li onClick={() => setActiveSearch(!activeSearch)}>
                <FontAwesomeIcon icon={faMagnifyingGlass} className="text-white cursor-pointer" size="lg" />

              </li>
              <li>
                <Link to={user ? "/dashboard" : "/login"} className="c_btn">
                  {user ? "Dashboard" : "Business Login"}
                </Link>
              </li>
            </ul>
          </div>

          <div className="right_box d-block d-lg-none">
            <div className="menu_controller" onClick={() => setIsHeaderActive(!isHeaderActive)}>

              <svg width="800px" height="800px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">

                <path fill="#000000" d="M19 4a1 1 0 01-1 1H2a1 1 0 010-2h16a1 1 0 011 1zm0 6a1 1 0 01-1 1H2a1 1 0 110-2h16a1 1 0 011 1zm-1 7a1 1 0 100-2H2a1 1 0 100 2h16z" />

              </svg>
            </div>
          </div>
        </div>
      </div>

      {activeSearch && <div className="container custom_container">
          <div className="row">
            <div className="col">
              <div className="d-flex justify-content-center align-items-center gap-5">
                <FontAwesomeIcon icon={faMagnifyingGlass} className="text-white cursor-pointer" size="2xl" />

                <input type="text" className="custom_input" placeholder="Search..." value={searchTerm} onChange={handleSearchChange} ref={inputRef} />

              </div>
              {searchTerm && <div className="container mt-10">
                  <div className="row">
                    <div className="col">
                      <span style={{
                  color: "#86868B"
                }} className="fs-5">
                        Suggested Business
                      </span>
                      <div className="d-flex flex-column gap-3 mt-3">
                        {businesses && businesses.map((business, index) => business.name && <div className="d-flex fs-4 gap-5 align-items-center ms-2" key={index} style={{
                    cursor: "pointer",
                    maxHeight: "30vh",
                    overflowY: "auto"
                  }} onClick={() => handleBusinessClick(business.id)}>


                                  <FontAwesomeIcon icon={faArrowRight} style={{
                      color: "#86868B"
                    }} />

                                  <span style={{
                      color: "#86868B"
                    }} dangerouslySetInnerHTML={{
                      __html: highlightMatch(business.name)
                    }}>
                        </span>
                                </div>)}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-10">
                    <div className="col">
                      <span style={{
                  color: "#86868B"
                }} className="fs-5">
                        Suggested Products
                      </span>
                      <div className="d-flex flex-column gap-3 mt-3">
                        {products.map((product, index) => product.name && <div className="d-flex fs-4 gap-5 align-items-center ms-2" key={index} style={{
                    cursor: "pointer",
                    maxHeight: "30vh",
                    overflowY: "auto"
                  }} onClick={() => handleProductClick(product.id)}>

                                <FontAwesomeIcon icon={faArrowRight} style={{
                      color: "#86868B"
                    }} />

                                <span style={{
                      color: "#86868B"
                    }} dangerouslySetInnerHTML={{
                      __html: highlightMatch(product.name)
                    }}>
                        </span>
                              </div>)}
                      </div>
                    </div>
                  </div>
                </div>}

              {!searchTerm && <div className="container mt-10">
                  <div className="row">
                    <div className="col-4">
                      <span style={{
                  color: "#86868B"
                }} className="fs-5">
                        Recent Businesses
                      </span>
                      <div className="d-flex flex-column gap-3 mt-3">
                        {recentBusinesses.map((business, index) => <Link key={index} to={`business-details/${business.id}`} className="d-flex fs-4 gap-5 align-items-center ms-2">

                            <FontAwesomeIcon icon={faArrowRight} style={{
                      color: "#86868B"
                    }} />

                            <span className="text-white">{business.name}</span>
                          </Link>)}
                      </div>
                    </div>
                    <div className="col-4">
                      <span style={{
                  color: "#86868B"
                }} className="fs-5">
                        Recent Products
                      </span>
                      <div className="d-flex flex-column gap-3 mt-3">
                        {recentProducts.map((product, index) => <Link key={index} to={`products/${product.id}`} className="d-flex fs-4 gap-5 align-items-center ms-2">

                            <FontAwesomeIcon icon={faArrowRight} style={{
                      color: "#86868B"
                    }} />

                            <span className="text-white">{product.name}</span>
                          </Link>)}
                      </div>
                    </div>
                  </div>
                </div>}

            </div>
          </div>
        </div>}


      <div className={`mobile_menu ${isHeaderActive && "active"}`}>
        <div className="container">
          <ul className="menu">
            {menus && menus.map((menu, index) => <li key={index}>
                  <Link to={menu.url}>{menu.title}</Link>
                </li>)}
            <li>
              <Link to="/login">Business Login</Link>
            </li>
          </ul>

          {/*  
            <ul className="icon_list">
             <li className="search">
               <svg
                 xmlns="http://www.w3.org/2000/svg"
                 width="18"
                 height="18"
                 viewBox="0 0 18 18"
                 fill="none"
               >
                 <path
                   opacity="0.3"
                   d="M16.9162 15.1836L14.2745 12.6836C13.7723 13.3302 13.1703 13.8928 12.4912 14.3503L15.2079 16.9336C15.3221 17.067 15.4633 17.1747 15.6223 17.2495C15.7812 17.3243 15.9542 17.3645 16.1298 17.3675C16.3054 17.3705 16.4796 17.3362 16.641 17.2669C16.8024 17.1976 16.9473 17.0949 17.066 16.9654C17.1847 16.836 17.2747 16.6829 17.3299 16.5161C17.385 16.3494 17.4042 16.1728 17.3861 15.9981C17.368 15.8234 17.3131 15.6545 17.2249 15.5026C17.1367 15.3507 17.0173 15.2193 16.8745 15.1169L16.9162 15.1836Z"
                   fill="#7E8299"
                 />
                 <path
                   d="M8.28321 0.667969C6.79985 0.667969 5.3498 1.10784 4.11643 1.93195C2.88306 2.75606 1.92177 3.9274 1.35411 5.29784C0.786453 6.66829 0.637928 8.17629 0.927317 9.63115C1.21671 11.086 1.93101 12.4224 2.97991 13.4713C4.0288 14.5202 5.36517 15.2345 6.82003 15.5239C8.27489 15.8132 9.78289 15.6647 11.1533 15.0971C12.5238 14.5294 13.6951 13.5681 14.5192 12.3347C15.3433 11.1014 15.7832 9.65133 15.7832 8.16797C15.7832 6.17885 14.993 4.27119 13.5865 2.86467C12.18 1.45814 10.2723 0.667969 8.28321 0.667969ZM8.28321 13.468C7.23697 13.468 6.21421 13.1578 5.34418 12.5767C4.47415 11.9956 3.7959 11.1697 3.39515 10.2033C2.99439 9.23682 2.88911 8.17327 3.09262 7.14701C3.29612 6.12076 3.79928 5.17786 4.5385 4.43747C5.27772 3.69709 6.21982 3.19245 7.24575 2.98733C8.27169 2.78221 9.3354 2.88582 10.3025 3.28505C11.2695 3.68429 12.0966 4.36124 12.679 5.23035C13.2615 6.09946 13.5732 7.12173 13.5749 8.16797C13.5771 8.86389 13.4418 9.55339 13.1767 10.1969C12.9117 10.8403 12.5221 11.4251 12.0304 11.9176C11.5387 12.4101 10.9545 12.8005 10.3115 13.0666C9.66841 13.3327 8.97913 13.4691 8.28321 13.468V13.468Z"
                   fill="#7E8299"
                 />
               </svg>
             </li>
             <li className="message">
               <svg
                 xmlns="http://www.w3.org/2000/svg"
                 width="18"
                 height="18"
                 viewBox="0 0 18 18"
                 fill="none"
               >
                 <path
                   d="M15.1336 5.025C16.3486 5.025 17.3336 4.04003 17.3336 2.825C17.3336 1.60997 16.3486 0.625 15.1336 0.625C13.9186 0.625 12.9336 1.60997 12.9336 2.825C12.9336 4.04003 13.9186 5.025 15.1336 5.025Z"
                   fill="#7E8299"
                 />
                 <g opacity="0.3">
                   <path
                     d="M15.1338 6.21826C14.2349 6.21606 13.3735 5.85802 12.738 5.22244C12.1024 4.58685 11.7443 3.72545 11.7421 2.8266C11.7444 2.26674 11.8877 1.71648 12.1588 1.2266H4.65879C4.14742 1.2244 3.64066 1.32323 3.16758 1.5174C2.69451 1.71157 2.26445 1.99727 1.90208 2.35809C1.53971 2.71891 1.25217 3.14775 1.05598 3.61998C0.859779 4.09222 0.758784 4.59856 0.758789 5.10993V11.0349C0.758789 12.0649 1.16792 13.0526 1.89619 13.7809C2.62446 14.5091 3.6122 14.9183 4.64212 14.9183H5.90879C6.08511 14.9189 6.25874 14.9615 6.41527 15.0427C6.5718 15.1238 6.70671 15.2412 6.80879 15.3849L7.70046 16.6516C7.81794 16.8201 7.97438 16.9577 8.15646 17.0527C8.33855 17.1477 8.5409 17.1973 8.74629 17.1973C8.95168 17.1973 9.15402 17.1477 9.33611 17.0527C9.5182 16.9577 9.67464 16.8201 9.79212 16.6516L10.6671 15.3849C10.7692 15.2412 10.9041 15.1238 11.0606 15.0427C11.2172 14.9615 11.3908 14.9189 11.5671 14.9183H12.8338C13.8637 14.9183 14.8515 14.5091 15.5797 13.7809C16.308 13.0526 16.7171 12.0649 16.7171 11.0349V5.8016C16.2322 6.06996 15.688 6.21317 15.1338 6.21826Z"
                     fill="#7E8299"
                   />
                 </g>
                 <path
                   d="M5.55046 9.20833C5.98768 9.20833 6.34212 8.85389 6.34212 8.41667C6.34212 7.97944 5.98768 7.625 5.55046 7.625C5.11323 7.625 4.75879 7.97944 4.75879 8.41667C4.75879 8.85389 5.11323 9.20833 5.55046 9.20833Z"
                   fill="black"
                 />
                 <path
                   d="M8.74186 9.20833C9.17909 9.20833 9.53353 8.85389 9.53353 8.41667C9.53353 7.97944 9.17909 7.625 8.74186 7.625C8.30464 7.625 7.9502 7.97944 7.9502 8.41667C7.9502 8.85389 8.30464 9.20833 8.74186 9.20833Z"
                   fill="black"
                 />
                 <path
                   d="M8.74186 9.20833C9.17909 9.20833 9.53353 8.85389 9.53353 8.41667C9.53353 7.97944 9.17909 7.625 8.74186 7.625C8.30464 7.625 7.9502 7.97944 7.9502 8.41667C7.9502 8.85389 8.30464 9.20833 8.74186 9.20833Z"
                   fill="black"
                 />
                 <path
                   d="M11.9421 9.20833C12.3793 9.20833 12.7337 8.85389 12.7337 8.41667C12.7337 7.97944 12.3793 7.625 11.9421 7.625C11.5048 7.625 11.1504 7.97944 11.1504 8.41667C11.1504 8.85389 11.5048 9.20833 11.9421 9.20833Z"
                   fill="black"
                 />
               </svg>
             </li>
             <li className="react">
               <svg
                 xmlns="http://www.w3.org/2000/svg"
                 width="18"
                 height="16"
                 viewBox="0 0 18 16"
                 fill="none"
               >
                 <path
                   opacity="0.3"
                   d="M14.3582 0.767745C13.5782 0.495548 12.7463 0.405103 11.926 0.503308C11.1057 0.601514 10.3186 0.885777 9.62489 1.33441C9.51222 1.40826 9.38044 1.4476 9.24572 1.4476C9.11101 1.4476 8.97922 1.40826 8.86655 1.33441C8.22933 0.923199 7.51275 0.650599 6.76334 0.534311C6.01392 0.418022 5.24843 0.460645 4.51655 0.659412C3.43473 0.946595 2.47845 1.58392 1.79703 2.47188C1.11561 3.35985 0.747396 4.44846 0.749888 5.56774C0.666554 11.3344 6.63322 14.4844 8.60822 15.3844C8.80875 15.4759 9.02543 15.5266 9.24571 15.5338C9.466 15.5409 9.68551 15.5043 9.89155 15.4261C11.8582 14.6677 17.6249 11.9344 17.7332 5.50108C17.7186 4.46255 17.3877 3.45317 16.7847 2.60751C16.1817 1.76184 15.3353 1.12006 14.3582 0.767745V0.767745Z"
                   fill="#7E8299"
                 />
                 <path
                   d="M14.3586 0.767745C13.5785 0.495548 12.7466 0.405103 11.9263 0.503308C11.106 0.601514 10.319 0.885777 9.62525 1.33441C9.51258 1.40826 9.3808 1.4476 9.24609 1.4476C9.11137 1.4476 8.97959 1.40826 8.86692 1.33441C8.2297 0.923199 7.51312 0.650599 6.7637 0.534311C6.01429 0.418022 5.24879 0.460645 4.51692 0.659412C3.4351 0.946595 2.47882 1.58392 1.7974 2.47188C1.11597 3.35985 0.747761 4.44846 0.750253 5.56774C0.745855 6.12725 0.798911 6.68574 0.908586 7.23441H17.5753C17.6814 6.66275 17.7344 6.0825 17.7336 5.50108C17.7189 4.46255 17.388 3.45317 16.7851 2.60751C16.1821 1.76184 15.3356 1.12006 14.3586 0.767745V0.767745Z"
                   fill="#7E8299"
                 />
               </svg>
             </li>
             <li className="notification">
               <svg
                 xmlns="http://www.w3.org/2000/svg"
                 width="16"
                 height="18"
                 viewBox="0 0 16 18"
                 fill="none"
               >
                 <path
                   opacity="0.3"
                   d="M13.0667 0.667969H2.93333C2.29513 0.667875 1.68243 0.918516 1.22727 1.36588C0.772113 1.81325 0.510928 2.42152 0.5 3.05964V12.2263C0.5 12.8717 0.756368 13.4906 1.21271 13.9469C1.66905 14.4033 2.28797 14.6596 2.93333 14.6596H3.88333C4.13853 14.6578 4.39127 14.7095 4.62524 14.8114C4.85921 14.9133 5.06921 15.0632 5.24167 15.2513L6.65833 16.7846C6.8319 16.9669 7.04069 17.1121 7.27204 17.2112C7.50338 17.3104 7.75246 17.3615 8.00417 17.3615C8.25587 17.3615 8.50495 17.3104 8.7363 17.2112C8.96764 17.1121 9.17643 16.9669 9.35 16.7846L10.8583 15.2013C11.0307 15.0216 11.2378 14.8788 11.4669 14.7814C11.6961 14.684 11.9427 14.634 12.1917 14.6346H13.025C13.3459 14.6401 13.6648 14.5821 13.9632 14.4639C14.2616 14.3456 14.5337 14.1695 14.7638 13.9456C14.9938 13.7218 15.1773 13.4546 15.3037 13.1595C15.43 12.8644 15.4967 12.5473 15.5 12.2263V3.05964C15.4891 2.42152 15.2279 1.81325 14.7727 1.36588C14.3176 0.918516 13.7049 0.667875 13.0667 0.667969V0.667969Z"
                   fill="#7E8299"
                 />
                 <path
                   d="M6.60811 10.9328H4.29978C4.08466 10.9325 3.87419 10.8701 3.6937 10.7531C3.5132 10.636 3.37035 10.4693 3.28233 10.2731C3.1943 10.0768 3.16485 9.85923 3.19752 9.6466C3.23018 9.43397 3.32357 9.2353 3.46645 9.07448C3.79281 8.73577 4.15302 8.4314 4.54145 8.16615C5.18311 7.66615 5.98311 7.04115 5.98311 6.49948C5.98703 6.40174 5.97136 6.3042 5.93701 6.2126C5.90266 6.12101 5.85034 6.03721 5.78311 5.96615C5.70993 5.89654 5.62358 5.84226 5.52913 5.80649C5.43469 5.77071 5.33404 5.75416 5.23311 5.75781C5.0342 5.75781 4.84344 5.83683 4.70278 5.97748C4.56213 6.11813 4.48311 6.3089 4.48311 6.50781C4.48311 6.67357 4.41727 6.83254 4.30006 6.94975C4.18285 7.06696 4.02388 7.13281 3.85811 7.13281C3.69235 7.13281 3.53338 7.06696 3.41617 6.94975C3.29896 6.83254 3.23311 6.67357 3.23311 6.50781C3.23311 6.24517 3.28485 5.9851 3.38536 5.74245C3.48586 5.49979 3.63318 5.27932 3.8189 5.0936C4.00462 4.90788 4.2251 4.76056 4.46775 4.66005C4.7104 4.55954 4.97047 4.50781 5.23311 4.50781C5.49576 4.50781 5.75583 4.55954 5.99848 4.66005C6.24113 4.76056 6.46161 4.90788 6.64733 5.0936C6.83305 5.27932 6.98036 5.49979 7.08087 5.74245C7.18138 5.9851 7.23311 6.24517 7.23311 6.50781C7.23311 7.64115 6.20812 8.43281 5.30812 9.13281C5.04978 9.33281 4.79978 9.52448 4.61645 9.69948H6.60811C6.77387 9.69948 6.93285 9.76533 7.05006 9.88254C7.16727 9.99975 7.23311 10.1587 7.23311 10.3245C7.23311 10.4902 7.16727 10.6492 7.05006 10.7664C6.93285 10.8836 6.77387 10.9495 6.60811 10.9495V10.9328Z"
                   fill="#7E8299"
                 />
                 <path
                   d="M11.2837 10.9362C11.2021 10.9362 11.1212 10.92 11.0458 10.8885C10.9705 10.857 10.9021 10.8108 10.8447 10.7527C10.7874 10.6945 10.7421 10.6256 10.7116 10.5498C10.6811 10.474 10.666 10.3929 10.6671 10.3112V9.76122H8.76708C8.59388 9.75643 8.42514 9.70517 8.27854 9.61281C8.13193 9.52045 8.01285 9.39038 7.93375 9.23622C7.84983 9.07862 7.8116 8.90072 7.82338 8.72255C7.83516 8.54439 7.89647 8.37306 8.00042 8.22789L10.2004 5.03622C10.319 4.86346 10.4897 4.7331 10.6875 4.66414C10.8854 4.59519 11.1001 4.59124 11.3004 4.65289C11.5011 4.71438 11.6763 4.83952 11.7996 5.00941C11.9229 5.17929 11.9875 5.38469 11.9838 5.59455V8.51122H12.3171C12.4828 8.51122 12.6418 8.57707 12.759 8.69428C12.8762 8.81149 12.9421 8.97046 12.9421 9.13622C12.9421 9.30198 12.8762 9.46095 12.759 9.57816C12.6418 9.69537 12.4828 9.76122 12.3171 9.76122H11.9754V10.3196C11.9748 10.4069 11.9558 10.493 11.9198 10.5726C11.8838 10.6521 11.8315 10.7232 11.7664 10.7813C11.7012 10.8394 11.6246 10.8832 11.5415 10.9099C11.4583 10.9366 11.3706 10.9455 11.2837 10.9362V10.9362ZM9.28375 8.51122H10.6671V6.46956L9.28375 8.51122Z"
                   fill="#7E8299"
                 />
               </svg>
             </li>
             <li className="account">
               <Link to={user ? "/dashboard" : "/login"}>
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   width="18"
                   height="18"
                   viewBox="0 0 18 18"
                   fill="none"
                 >
                   <path
                     opacity="0.3"
                     d="M6.5252 8.5013C8.70672 8.5013 10.4752 6.74775 10.4752 4.58464C10.4752 2.42152 8.70672 0.667969 6.5252 0.667969C4.34367 0.667969 2.5752 2.42152 2.5752 4.58464C2.5752 6.74775 4.34367 8.5013 6.5252 8.5013Z"
                     fill="#7E8299"
                   />
                   <path
                     d="M6.52533 17.3333C9.76079 17.3333 12.3837 15.5798 12.3837 13.4167C12.3837 11.2536 9.76079 9.5 6.52533 9.5C3.28986 9.5 0.666992 11.2536 0.666992 13.4167C0.666992 15.5798 3.28986 17.3333 6.52533 17.3333Z"
                     fill="#7E8299"
                   />
                   <g opacity="0.3">
                     <path
                       d="M12.659 2.33599C12.2006 2.33651 11.748 2.43899 11.334 2.63599C11.6833 3.48361 11.7965 4.41002 11.6615 5.3168C11.5265 6.22358 11.1484 7.07686 10.5674 7.78599C10.9444 8.12926 11.3994 8.37536 11.893 8.50293C12.3866 8.6305 12.9039 8.63569 13.4 8.51805C13.8961 8.4004 14.356 8.16348 14.7398 7.82783C15.1236 7.49219 15.4196 7.06797 15.6023 6.59197C15.785 6.11598 15.8488 5.6026 15.7881 5.09638C15.7274 4.59016 15.5441 4.10639 15.2541 3.68705C14.9641 3.26771 14.5762 2.92548 14.124 2.69004C13.6717 2.45459 13.1689 2.33306 12.659 2.33599V2.33599Z"
                       fill="#7E8299"
                     />
                   </g>
                   <path
                     d="M12.6583 9.41797C12.1908 9.41758 11.7244 9.46505 11.2666 9.55964C11.955 9.94957 12.5327 10.5084 12.9453 11.1835C13.3579 11.8585 13.5918 12.6275 13.6249 13.418C13.6212 14.2198 13.3806 15.0026 12.9333 15.668C15.3833 15.5763 17.3333 14.218 17.3333 12.5513C17.3333 10.8846 15.2416 9.41797 12.6583 9.41797Z"
                     fill="black"
                   />
                 </svg>
               </Link>
             </li>
            </ul>
            */}
        </div>
      </div>
    </header>;
}
_s(Header, "qqvIgbcxEETwtykuB97U5Qfp7OY=", false, function () {
  return [useLocation, useNavigate, useOptionalUser];
});
_c = Header;
var _c;
$RefreshReg$(_c, "Header");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;